@tailwind base;
@tailwind components;
@tailwind utilities;


/* 
https://tailwind-elements.com/ */

/* *{
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
*:focus {
  outline: none;
}

body {
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
}

/* make scrollbar transparent */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;

}

::-webkit-file-upload-button {
  visibility: visible;
}

input {
  background-color: transparent;
}


/* for more info https://codesandbox.io/s/floating-scrollbar-lwqrv?from-embed=&file=/styles.css:36-609 */
.frame {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100vh;

}

.frame::-webkit-scrollbar:vertical {
  width: 5px;
  position: absolute;
}

.frame::-webkit-scrollbar-track {
  background-color: transparentize(#ccc, 0.5);
}

.frame::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #a0aec0;
}

/* for more info https://codepen.io/mash_25/pen/yLLgWVo */

.frame-x {
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.frame-x::-webkit-scrollbar {
  width: 5px;
}

.frame-x::-webkit-scrollbar:horizontal {
  height: 5px;
}

.frame-x::-webkit-scrollbar-track {
  background-color: transparentize(#ccc, 0.7);
}

.frame-x::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #a0aec0;
}

.backgroundLandingPage {

  background-image: linear-gradient(to right bottom, #002639, #2b3965, #73437f, #bb467c, #f05b5d);

}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


.swiper-pagination-bullet{
  width: 25px !important;
  height: 25px !important;
  color: white !important;
  background-color: #002639 !important;
}

